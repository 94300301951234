
import React, {useEffect} from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import RichText from '../components/richText';
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import DateResolver from "../utils/dateResolver";
import { useLocation } from "@reach/router";
import { StringToSlug } from '../utils/stringToSlug';


const BlogPost = ({ data, pageContext }) => {


  const { href } = useLocation();

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);

  useEffect(() => {
    let headingsList = document.getElementById('js-blogpost-content').getElementsByTagName('h2');
    let headingsNav = document.getElementById('js-blogpost-nav')

    if(headingsList.length > 0){
      let index = 0;

        for (let item of headingsList) {

          index++;
          item.setAttribute('id', 'heading-' +index)
          
          let el = document.createElement('li');
          el.classList.add('c-blogpost-summary__item');
          
          let link = document.createElement('a');
          link.setAttribute('href','#heading-' +index);
          link.classList.add('c-blogpost-summary__link');
          link.innerText = item.innerText;

          link.addEventListener('click', (e) => { clickHandler(e, link)});

          headingsNav.appendChild(el);
          el.appendChild(link);

        }

    }else{
      document.querySelectorAll('.c-blogpost__nav')[0].style.display = 'none';
    }

  }, [])

    const clickHandler = (e, link) => {
          e.preventDefault();
          const href = link.getAttribute("href");
          const offsetTop = document.querySelector(href).offsetTop - 120;

      
          window.scroll({
                top: offsetTop,
                behavior: "smooth"
          });
    }


    //Patch data
    if (!data) return null

    const $metaData = data.prismicMetadata.data;
    const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
    const $footer = data.prismicFooter.data;
    const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
    const $socials = data.prismicSocials ? data.prismicSocials.data : null;
    const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;
    const $blogpost = data.prismicBlogPost ? data.prismicBlogPost : null;


  return (
   
    <>

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs} notSeoLogo={true}>

      <Seo metadata={$metaData} title={$blogpost.data.title ? $blogpost.data.title : null} description={$blogpost.data.description ? $blogpost.data.description : null}  avatar={$blogpost.data.image_preview ? $blogpost.data.image_preview.url : null} ></Seo>

      <Helmet>

        <link href={withPrefix('../../assets/css/sitio/blog.content.css')} rel="stylesheet" type="text/css" />

      </Helmet>

      <main>

        <article>

            <div className="c-blogpost-hero c-hero o-wrapper c-heading">

              <Link to={$blogpost.data.category.url} className="c-blogpost__link u-tap-size">{$blogpost.data.category.document.data.name}</Link>

                <div className="c-blogpost__heading c-heading__heading">
                  <h1>{$blogpost.data.title}</h1>
                </div>

                {$blogpost.data.description && <p className="c-blogpost__description">{$blogpost.data.description}</p>}

                <div className="c-blogpost__info">

                  {$blogpost.data.author.document && <Link to={`${$blogpost.data.author.url}`} className="c-blogpost__author">
                        <div className="c-blogpost__author-img">
                        {$blogpost.data.author.document.data.image && $blogpost.data.author.document.data.image.url &&
                              <GatsbyImage className="c-pic--abs c-pic" image={$blogpost.data.author.document.data.image.gatsbyImageData} />
                        }
                        </div>

                  {$blogpost.data.author.document.data.name}

                  </Link>}

                  <span className="c-blogpost__date">{DateResolver($blogpost.last_publication_date)}</span>

                </div>

            </div>

            {$blogpost.data.image && $blogpost.data.image.url && 
              <div className="o-wrapper o-wrapper--xl">
                <GatsbyImage className="c-pic" image={$blogpost.data.image.gatsbyImageData} /> 
                {$blogpost.data.image_alt && <div className="c-blogpost__alt"><RichText render={$blogpost.data.image_alt.richText} /> </div>}
              </div>
            }

            <div className="o-wrapper o-wrapper--xl">
                  
                <div className="c-blogpost__holder">

                  <div className="c-blogpost__aside">

                    <div className="c-blogpost__sticky">

                      <nav className="c-blogpost__nav">
                        
                        <h2 className="c-blogpost__subtitle">Summary</h2>

                        <ul id="js-blogpost-nav" className="c-blogpost-summary"></ul>

                      </nav>

                      {$blogpost.data.tags.length > 0 &&
                          <>
                          <p className="c-blogpost__subtitle">Tags</p>
                          <ul className="c-blogpost__tags">
                            {$blogpost.data.tags.map((item, i) => {
                              return(
                                item.tag != null &&
                                <li>
                                  <Link to={`/blog/tags/${StringToSlug(item.tag)}`} className="c-blogpost__tag u-tap-size">{item.tag}</Link>
                                </li>
                              )
                            })}
                          </ul>
                          </>
                      }

                    </div>

                  </div>

                  <div id="js-blogpost-content" className="c-blogpost__content u-content">
                    <RichText render={$blogpost.data.content.richText} />
                  </div>
                      
                  <div className="">

                    <ul className="c-blogpost__shares c-blogpost-shares c-blogpost__sticky">
                      <li className="c-blogpost-shares__item">
                        <a target="_blank" className="c-blogpost-shares__link icon-social-twitter" href={`https://twitter.com/intent/tweet?url=${href}?text=${$blogpost.data.title}`}></a>
                      </li>
                      <li className="c-blogpost-shares__item">
                        <a target="_blank" className="c-blogpost-shares__link icon-social-linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}`}></a>
                      </li>
                      <li className="c-blogpost-shares__item">
                        <a target="_blank" className="c-blogpost-shares__link icon-mail" href={`mailto:?body=${href}&subject=${$blogpost.data.title}`}></a>
                      </li>
                    </ul>

                  </div>

                </div>




            </div>

        </article>
  
      </main>

    </Layout>

    </>


  )
}


export const query = graphql`
  query blogPostQuery($id: String) {
    prismicBlogPost (uid: { eq: $id }){
      uid
      first_publication_date
      last_publication_date
      data{
        title
        description
        tags{
          tag
        }
        content{
          richText
        }
        image {
          gatsbyImageData(
            
            breakpoints: [414,767,768,980,1024,1440,1680,1920]
          )
          url
        }
        image_preview {
          gatsbyImageData(
            
            breakpoints: [414,767,768,980,1024,1440,1680,1920]
          )
          url
        }
        image_alt{
          richText
        }
        category {
        url
        uid
        document {
          ... on PrismicBlogCategory {
            data {
              name
            }
          }
        }
      }
        author {
        url
        uid
        document {
          ... on PrismicBlogAuthor {
            data {
              name
              image {
                gatsbyImageData(
                  
                  breakpoints: [414,767,768,980,1024,1440,1680,1920]
                )
                url
              }
            }
          }
        }
      }
  
    }

    },
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default BlogPost
